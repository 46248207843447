<template>
  <div class="text-center">
    <p class="font-bold">
      {{ l10nTitle }}
    </p>

    <!-- Relevance percentage -->
    <div class="h-3 relative w-full rounded-full overflow-hidden mb-1">
      <div class="w-full h-full bg-gray-200 absolute" />
      <div
        class="h-full absolute bg-theme-500"
        :style="`width: ${relevancePercentage}%`"
      />
    </div>

    <p
      class="text-xs mb-2"
    >
      {{ t('points_of_interest.duplicates.match_percentage', { percentage: relevancePercentage }) }}
    </p>

    <div
      class="flex items-center justify-between"
    >
      <!-- Matching criteria -->
      <div class="flex flex-wrap justify-center">
        <template
          v-for="(criterium, key) in relevanceCriteria"
          :key="key"
        >
          <div
            v-if="resource.attributes[`${criterium}_match`] > 0"
            class="flex items-center text-xs px-3 py-1 m-1 bg-theme-500 text-white rounded-full"
          >
            <app-icon
              name="check"
              library="fontawesome"
            />

            <span
              class="ml-1"
            >
              {{ t(`validation.attributes.${criterium}`) }}
            </span>
          </div>
        </template>
      </div>

      <!-- Selection checkbox -->
      <app-icon
        library="coolicon"
        :name="selected ? 'checkbox_square' : 'checkbox'"
        size="text-3xl"
        :class="selected ? 'text-theme-500' : ''"
      />
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppIcon from '@shared/components/ui/AppIcon.vue'
import usePointOfInterest from '@shared/hooks/resources/pointOfInterest'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Duplicate selected or not
  selected: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const {
  l10nTitle,
  relevancePercentage,
  relevanceCriteria,
} = usePointOfInterest(props)
</script>
