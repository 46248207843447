<template>
  <app-alert
    design="info"
    :title="t('claims.notice.missing-info.title')"
    class="mb-4"
  >
    <p>
      {{ t('claims.notice.missing-info.state') }}
    </p>

    <p>
      {{ resource?.attributes.denial_reason }}
    </p>

    <p>
      {{ t('claims.notice.missing-info.infos') }}
    </p>

    <claim-edit-action
      :resource="resource"
    />
  </app-alert>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

import AppAlert from '@shared/components/ui/AppAlert.vue'
import ClaimEditAction from '@extranet/components/features/claim/actions/ClaimEditAction.vue'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
</script>
