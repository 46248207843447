<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors />

    <app-alert
      design="info"
      :title="t('form.hints.translatable.pro_global_title')"
      class="mb-4"
    >
      <p>
        {{ t('advice.form.hints.translation') }}
      </p>
    </app-alert>

    <form-translated
      :form-group-props="{
        name: 'content',
        type: 'textarea',
        formLabelProps: {
          required: true,
        },
        formControlProps: {
          rows: 4,
          limitChars: 255,
        },
      }"
    />

    <div class="flex flex-col items-center space-y-4">
      <app-button
        :label="adviceExist ? t('advice.ui.edit') : t('advice.ui.add')"
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        class="w-max"
        @submitted="handleSubmit"
      />
    </div>
  </form>

  <base-button
    v-if="canDeleteAdvice"
    classes="text-red-600 mt-4"
    :label="t('advice.form.links.remove_advice')"
    @click="showModalConfirmation"
  />

  <app-overlay
    :show="displayModalConfirmation"
  >
    <app-modal
      @closed="handleCancel"
    >
      <p>
        {{ t('advice.form.confirmations.delete') }}
      </p>

      <form
        class="w-max mt-6 mx-auto space-x-2"
        novalidate
        @submit.prevent="handleSubmit"
      >
        <form-global-errors />

        <form-group
          rules="required"
          name="delete"
          type="hidden"
          :initial-value="true"
        />

        <app-button
          :label="t('common.yes')"
          feature="report"
          type="submit"
          @submitted="handleSubmit"
        />

        <app-button
          :label="t('common.no')"
          @click="handleCancel"
        />
      </form>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useAdviceForm from '@shared/hooks/resources/form/advice'
import AppAlert from '@shared/components/ui/AppAlert.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import BaseButton from '@shared/components/ui/buttons/BaseButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormTranslated from '@shared/components/form/FormTranslated.vue'

const { t } = useI18n()

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Advice Existing
  adviceExist: {
    type: Boolean,
    default: false,
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

// --------- DELETE ----------

const canDeleteAdvice = computed(() => (
  props.adviceExist && props.resource?.authorizations?.delete
))

const displayModalConfirmation = ref(false)

function showModalConfirmation() {
  displayModalConfirmation.value = true
}
function handleCancel() {
  displayModalConfirmation.value = false
}

// ---------- FORM ----------

const {
  handleSubmit,
  invalid,
} = useAdviceForm(props, { emits })
</script>
