<template>
  <teleport-wrapper
    v-if="pointOfInterest && !store.state.spinner.active"
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <point-of-interest-show
    v-if="pointOfInterest && !store.state.spinner.active"
    :resource="pointOfInterest"
    :destroy-callback="handleDestroy"
    @claimed="handleClaim"
    @import-sync-disabled="handleImportSyncDisable"
    @claim-disputed="handleClaimDispute"
    @advice-updated="handleAdviceUpdated"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import {
  fetchPointOfInterest,
  destroyPointOfInterest,
  claimPointOfInterest,
  disableSynchronizationPointOfInterest,
} from '@shared/http/api'
import useLocale from '@shared/hooks/locale'
import breadcrumbPointOfInterest from '@extranet/breadcrumbs/pointOfInterest'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import PointOfInterestShow from '@extranet/components/resources/point_of_interest/PointOfInterestShow.vue'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const store = useStore()
const { getAttributeTranslation } = useLocale()

// ---------- POI ----------

const pointOfInterest = ref(null)

function fetchPoi() {
  store.commit('spinner/ENABLE')
  const { id } = route.params

  fetchPointOfInterest(id)
    .then((response) => {
      pointOfInterest.value = response.data.data
    })
    .finally(() => {
      store.commit('spinner/DISABLE')
    })
}

function handleDestroy() {
  destroyPointOfInterest(pointOfInterest.value.id).then(() => {
    router.push({ name: 'index-points-of-interest' })
    store.commit(
      'flashes/ADD_FLASH',
      { message: t('points_of_interest.destroy.success'), type: 'success' },
    )
  })
}

function handleClaim() {
  store.commit('spinner/DISABLE')
  claimPointOfInterest(pointOfInterest.value.id)
    .then(() => {
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('points_of_interest.claim.success'),
          type: 'success',
        },
      )
      fetchPoi()
    })
}

function handleImportSyncDisable() {
  store.commit('spinner/DISABLE')
  disableSynchronizationPointOfInterest(pointOfInterest.value.id)
    .then(() => {
      router.push({ name: 'edit-points-of-interest', params: { id: pointOfInterest.value.id } })
    })
}

function handleClaimDispute() {
  fetchPoi()
}

function handleAdviceUpdated($event) {
  fetchPoi()

  store.commit(
    'flashes/ADD_FLASH',
    {
      message: t(`advice.form.notifications.${$event}`),
      type: 'success',
    },
  )
}

// ---------- BREADCRUMBS ----------

const { show } = breadcrumbPointOfInterest()

const breadcrumbs = computed(() => (
  show({
    id: pointOfInterest.value?.id,
    label: getAttributeTranslation(pointOfInterest.value?.attributes?.title),
  })
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPoi()
})
</script>
