<template>
  <div
    class="flex items-center
    space-y-2 md:space-y-0 md:space-x-2"
  >
    <add-excursion-action
      :resource="resource"
      emphasis="low"
      hide-text
      :hide-icon="false"
    />

    <share-action
      :resource="resource"
      emphasis="low"
      hide-text
      :hide-icon="false"
    />

    <update-action
      :resource="resource"
      emphasis="low"
      hide-text
      :hide-icon="false"
      @import-sync-disabled="handleImportSyncDisabledModal"
    />

    <app-button
      v-if="resource.authorizations.delete"
      feature="delete"
      emphasis="low"
      hide-text
      :hide-icon="false"
      @click="handleDestroy"
    />

    <report-action
      :resource="resource"
      emphasis="low"
      hide-text
      :hide-icon="false"
    />
  </div>

  <!-- Delete modal -->
  <app-overlay
    :show="deleteConfirmModalDisplayed"
    @clicked="handleDestroyCancel"
  >
    <app-modal @closed="handleDestroyCancel">
      <app-confirm
        @cancelled="handleDestroyCancel"
        @confirmed="handleDestroyConfirm"
      >
        <p>
          {{
            t(
              'points_of_interest.destroy.confirm',
              { point_of_interest_name: getAttributeTranslation(resource.attributes.title) }
            )
          }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AddExcursionAction from '@extranet/components/resources/point_of_interest/actions/AddExcursionAction.vue'
import ShareAction from '@extranet/components/resources/point_of_interest/actions/ShareAction.vue'
import UpdateAction from '@extranet/components/resources/point_of_interest/actions/UpdateAction.vue'
import ReportAction from '@shared/components/resources/point_of_interest/actions/ReportAction.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
  importSyncDisabled: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'destroyed',
  'importSyncDisabled',
])

const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ---------- UPDATED ----------

function handleImportSyncDisabledModal() {
  emits('importSyncDisabled')
  props.importSyncDisabled()
}

// ---------- DELETE ----------

const deleteConfirmModalDisplayed = ref(false)

function handleDestroy() {
  deleteConfirmModalDisplayed.value = true
}

function handleDestroyConfirm() {
  deleteConfirmModalDisplayed.value = false
  emits('destroyed')
  props.destroyCallback()
}

function handleDestroyCancel() {
  deleteConfirmModalDisplayed.value = false
}
</script>
