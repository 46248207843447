<template>
  <app-button
    v-if="resource.authorizations.contest"
    font-size="sm"
    :label="t('common.actions.contest')"
    @click="handleContest"
  />

  <!-- Modal -->
  <app-overlay
    :show="contestConfirmModalDisplayed"
    @clicked="handleContestCancel"
  >
    <app-modal @closed="handleContestCancel">
      <contest-form
        :additional-errors="contestFormErrors"
        :submitting="contestFormSubmitting"
        @submitted="handleContestSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import { contestPointOfInterest } from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import ContestForm from '@extranet/components/resources/point_of_interest/actions/contest/ContestForm.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'claimDisputed',
])

const { t } = useI18n()
const store = useStore()

// ---------- CONTEST ----------

const contestConfirmModalDisplayed = ref(false)
const contestFormErrors = ref({})
const contestFormSubmitting = ref(false)

function handleContest() {
  contestConfirmModalDisplayed.value = true
}

function handleContestConfirm() {
  contestConfirmModalDisplayed.value = false
}

function handleContestCancel() {
  contestConfirmModalDisplayed.value = false
}

function handleContestSubmit(attributes) {
  contestFormSubmitting.value = true

  const params = {
    data: {
      attributes: { ...attributes },
    },
  }

  contestPointOfInterest(props.resource.id, params)
    .then(() => {
      handleContestConfirm()
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('claim_disputes.create.success'),
          type: 'success',
        },
      )
      emits('claimDisputed')
    })
    .catch((e) => {
      if (e.response?.data?.errors) {
        contestFormErrors.value = e.response.data.errors
      }
    })
    .finally(() => {
      contestFormSubmitting.value = false
    })
}
</script>
