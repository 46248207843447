<template>
  <div class="flex flex-row-reverse mt-2">
    <app-button
      font-size="sm"
      :label="t('claims.notice.actions.edit')"
      class="my-1 md:mx-1"
      color="orange"
      @click="router.push({
        name: 'edit-claims',
        params: { id: resource?.id },
      })"
    />
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'

defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const router = useRouter()
const { t } = useI18n()
</script>
