<template>
  <app-tutorial-button
    :tutorials="tutorials"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import AppTutorialButton from '@extranet/components/ui/help/AppTutorialButton.vue'
import TutorialPointOfInterestClaim from '@extranet/components/tutorials/point_of_interest/TutorialPointOfInterestClaim.vue'
import TutorialPointOfInterestFilters from '@extranet/components/tutorials/point_of_interest/TutorialPointOfInterestFilters.vue'

const { t } = useI18n()

// ---------- TUTORIALS ----------

const tutorials = computed(() => ([
  {
    menuItem: t('tutorials.extranet.points_of_interest.filters.main_title'),
    component: TutorialPointOfInterestFilters,
  },
  {
    menuItem: t('tutorials.extranet.points_of_interest.claim'),
    component: TutorialPointOfInterestClaim,
  },
]))
</script>
