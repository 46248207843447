<template>
  <form
    novalidate
    @submit.prevent="onSubmit"
  >
    <form-global-errors />

    <div class="flex justify-between">
      <form-group
        rules="required"
        name="contestant_last_name"
        type="text"
        class="w-1/2 mr-2"
        :initial-value="currentUser.attributes.last_name"
      />

      <form-group
        rules="required"
        name="contestant_first_name"
        type="text"
        class="w-1/2 ml-2"
        :initial-value="currentUser.attributes.first_name"
      />
    </div>

    <form-group
      rules="required|phone"
      name="contestant_phone"
      type="tel"
      :initial-value="currentUser.attributes.phone"
    />

    <form-group
      rules="required|email"
      name="contestant_email"
      type="email"
      :initial-value="currentUser.attributes.email"
    />

    <form-group
      rules="required"
      name="contestant_country"
      type="select"
      :label="t('validation.attributes.country')"
      :form-control-props="{
        options: claimCountriesOptions,
      }"
    />

    <form-group
      rules="required"
      name="contestant_company_type"
      type="select"
      :label="t('validation.attributes.company_type')"
      :form-control-props="{
        options: claimCompanyTypesOptions,
      }"
    />

    <form-group
      rules="required"
      name="contestant_message"
      type="textarea"
      :form-control-props="{
        rows: 6,
      }"
    />

    <app-button
      feature="confirm"
      width="1/2"
      type="submit"
      :loading="submitting"
      :disabled="submitting || invalid"
    />
  </form>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import useForm from '@shared/hooks/form/form'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'

const props = defineProps({
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'submitted',
])

// ---------- AUTH USER ----------

const store = useStore()

const currentUser = computed(() => (
  store.state.auth.user
))

// ---------- FORM ----------

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })

function onSubmit() {
  handleSubmit()
}

// ---------- COUNTRIES ----------

const { t } = useI18n()

const {
  claimCountriesOptions,
  claimCompanyTypesOptions,
} = useSelectOptions()
</script>
