<template>
  <claim-state-pending
    v-if="isPending"
    :resource="resource"
  />

  <claim-state-rejected
    v-if="isRejected"
    :resource="resource"
  />

  <claim-state-missing-info
    v-if="isMissingInfo"
    :resource="resource"
  />
</template>

<script setup>
import { computed } from 'vue'

import ClaimStatePending from '@extranet/components/features/claim//states/ClaimStatePending.vue'
import ClaimStateRejected from '@extranet/components/features/claim//states/ClaimStateRejected.vue'
import ClaimStateMissingInfo from '@extranet/components/features/claim//states/ClaimStateMissingInfo.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

// ---------- STATES ----------

const isPending = computed(() => (
  props.resource?.attributes.state === 'pending'
))

const isRejected = computed(() => (
  props.resource?.attributes.state === 'rejected'
))

const isMissingInfo = computed(() => (
  props.resource?.attributes.state === 'missing-info'
))
</script>
