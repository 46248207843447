<template>
  <teleport-wrapper to="#breadcrumbs">
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <point-of-interest-form
    :resource="pointOfInterest"
    :additional-errors="pointOfInterestFormErrors"
    :submitting="pointOfInterestFormSubmitting"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import breadcrumbPointOfInterest from '@extranet/breadcrumbs/pointOfInterest'
import usePointOfInterestForm from '@shared/hooks/resources/form/pointOfInterest'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import PointOfInterestForm from '@extranet/components/resources/point_of_interest/PointOfInterestForm.vue'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const {
  resource: pointOfInterest,
  errors: pointOfInterestFormErrors,
  submitting: pointOfInterestFormSubmitting,
  handleSubmit,
} = usePointOfInterestForm({
  onSubmitSuccess,
})

function onSubmitSuccess(response) {
  router.push({
    name: 'show-points-of-interest',
    params: { id: response.data.data?.id },
  })

  store.commit(
    'flashes/ADD_FLASH',
    {
      message: t('points_of_interest.store.success'),
      type: 'success',
    },
  )
}

// ---------- BREADCRUMBS ----------

const { create } = breadcrumbPointOfInterest()

const breadcrumbs = computed(() => (
  create()
))
</script>
