<template>
  <div>
    <div
      class="flex flex-col md:flex-row flex-wrap
      justify-end items-start md:items-center
      space-y-2 md:space-y-0 md:space-x-2"
    >
      <add-excursion-action
        :resource="resource"
      />

      <advice-action
        :resource="resource"
        @advice-updated="handleAdviceUpdated"
      />

      <broadcast-page-action
        v-if="resource.attributes.owned_by_auth_user"
        :resource="resource"
      />

      <share-action
        :resource="resource"
      />

      <update-action
        :resource="resource"
        @import-sync-disabled="handleImportSyncDisabledModal"
      />

      <claim-action
        :resource="resource"
        @claimed="handleClaimed"
      />

      <contest-action
        v-if="!claimDispute"
        :resource="resource"
        @claim-disputed="handleClaimDisputed"
      />

      <report-action
        :resource="resource"
      />

      <delete-action
        :resource="resource"
        :destroy-callback="destroyCallback"
        @destroyed="handleDestroyed"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import AddExcursionAction from '@extranet/components/resources/point_of_interest/actions/AddExcursionAction.vue'
import AdviceAction from '@extranet/components/resources/point_of_interest/actions/advices/AdviceAction.vue'
import BroadcastPageAction from '@extranet/components/resources/point_of_interest/actions/BroadcastPage/BroadcastPageAction.vue'
import ClaimAction from '@extranet/components/resources/point_of_interest/actions/ClaimAction.vue'
import ContestAction from '@extranet/components/resources/point_of_interest/actions/contest/ContestAction.vue'
import DeleteAction from '@extranet/components/resources/point_of_interest/actions/DeleteAction.vue'
import ReportAction from '@shared/components/resources/point_of_interest/actions/ReportAction.vue'
import ShareAction from '@extranet/components/resources/point_of_interest/actions/ShareAction.vue'
import UpdateAction from '@extranet/components/resources/point_of_interest/actions/UpdateAction.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'destroyed',
  'claimed',
  'claimDisputed',
  'importSyncDisabled',
  'adviceUpdated',
])

// ---------- DELETED ----------

function handleDestroyed() {
  emits('destroyed')
}

// ---------- UPDATED ----------

function handleImportSyncDisabledModal() {
  emits('importSyncDisabled')
}

// ---------- CLAIMED ----------

function handleClaimed() {
  emits('claimed')
}

// ---------- CLAIM DISPUTED ----------

function handleClaimDisputed() {
  emits('claimDisputed')
}

const claimDispute = computed(() => (
  props.resource.relationships?.claim_dispute
))

// ---------- ADVICE ----------

function handleAdviceUpdated($event) {
  emits('adviceUpdated', $event)
}
</script>
