<template>
  <div>
    <form-label
      :label="t('validation.attributes.hours')"
      class="block mb-1"
    />

    <div
      v-for="(hour, hourIndex) in hours"
      :key="hourIndex"
    >
      <form-group
        type="hidden"
        :name="`${name}.${hourIndex}._destroy`"
        :initial-value="false"
      />

      <template
        v-if="!hour._destroy"
      >
        <!-- Range checkbox -->
        <form-group
          v-if="activeHours.length === 1"
          type="checkbox"
          :name="`${name}.${hourIndex}.is_range`"
          :label="t('form.labels.point_of_interest.opening_hours_range')"
          flex-direction="row"
          :submittable="false"
          :initial-value="!isNil(hour.to)"
        />

        <form-group
          v-else
          type="hidden"
          :name="`${name}.${hourIndex}.is_range`"
          :submittable="false"
          :initial-value="true"
        />

        <div
          class="flex items-center"
        >
          <!-- Remove button -->
          <app-button
            feature="remove"
            :hide-icon="false"
            :hide-text="true"
            width="5"
            height="5"
            rounded="rounded-1/2"
            padding="p-2"
            :gradient="false"
            :icon-props="{ size: '2xs' }"
            border-width="1"
            class="mr-2"
            @click="handleRemove(hourIndex)"
          />

          <!-- Time fields -->
          <div
            class="flex gap-4"
          >
            <form-group
              type="time"
              :name="`${name}.${hourIndex}.from`"
              :label="isRange(hourIndex) ? t('form.labels.point_of_interest.opening_hours_from') : t('form.labels.point_of_interest.opening_hours')"
              error-label-i18n-key="hour"
              rules="required"
            />

            <form-group
              v-if="isRange(hourIndex)"
              type="time"
              :name="`${name}.${hourIndex}.to`"
              :label="t('form.labels.point_of_interest.opening_hours_to')"
              error-label-i18n-key="hour"
              rules="required"
            />
          </div>
        </div>
      </template>
    </div>

    <!-- Add button -->
    <app-button
      v-if="canAddHour"
      feature="add"
      :hide-icon="false"
      :hide-text="true"
      width="5"
      height="5"
      rounded="rounded-1/2"
      padding="p-2"
      :gradient="false"
      border-width="1"
      :icon-props="{ size: 'text-xs' }"
      class="mb-3"
      @click="handleAdd"
    />

    <form-hint
      :hint="t('form.hints.point_of_interest.opening_hours')"
    />
  </div>
</template>

<script setup>
import {
  inject,
  ref,
  computed,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { get, isNil } from 'lodash'
import { fromJS } from 'immutable'

import FormGroup from '@shared/components/form/FormGroup.vue'
import FormLabel from '@shared/components/form/FormLabel.vue'
import FormHint from '@shared/components/form/FormHint.vue'
import AppButton from '@shared/components/ui/AppButton.vue'

const props = defineProps({
  // Prefix used for form group's names
  name: {
    type: String,
    required: true,
  },
})

const { t } = useI18n()

const form = inject('form', ref({}))

const hours = computed(() => (
  get(form.value.values, props.name) ?? []
))

const activeHours = computed(() => (
  hours.value.filter((hour) => !hour._destroy)
))

function getOpeningHoursCopy() {
  return fromJS(hours.value).toJS()
}

function handleAdd() {
  const openingHoursCopy = getOpeningHoursCopy()
  openingHoursCopy.push({})
  form.value.setFieldValue(props.name, openingHoursCopy)
}

function handleRemove(hourIndex) {
  // Set "_destroy" form value to "true"
  form.value.setFieldValue(`${props.name}.${hourIndex}`, { _destroy: true })
}

function isRange(hourIndex) {
  return hours.value[hourIndex]?.is_range
}

function isActiveRange(hourIndex) {
  return activeHours.value[hourIndex]?.is_range
}

// Can add hour is there is no active hour,
// Or if first active one is a range
const canAddHour = computed(() => (
  activeHours.value.length === 0 || isActiveRange(0)
))
</script>
