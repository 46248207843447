<template>
  <app-video-player
    v-if="videoUrl"
    :src="videoUrl"
    class="w-38 h-22"
  />

  <form-error-messages
    :name="nameToUse('file')"
    :data-form-errors="true"
    :error-messages="fileErrors.flat()"
  />

  <form-group
    :name="nameToUse('id')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('_destroy')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('file')"
    :rules="fileRules"
    type="hidden"
  />

  <input
    ref="fileInput"
    type="file"
    class="hidden"
    :accept="videoMimeTypes"
    @change="handleFileUpload"
  >

  <form-group
    :name="nameToUse('position')"
    type="hidden"
  />

  <form-group
    :name="nameToUse('collection_name')"
    initial-value="videos"
    type="hidden"
  />
</template>

<script setup>
import { computed, ref } from 'vue'
import { get } from 'lodash'
import { useField } from 'vee-validate'

import AppVideoPlayer from '@shared/components/ui/AppVideoPlayer.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormErrorMessages from '@shared/components/form/FormErrorMessages.vue'
import useFormFields from '@shared/hooks/form/formFields'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Prefix to use in input name,
  // e.g.: if "media[0]" is prefix,
  // title input name will be "media[0].title"
  // to avoid conflicts with other form's title input
  namePrefix: {
    type: String,
    default: null,
  },
})

const {
  markedForDestruction,
  nameToUse,
  form,
} = useFormFields(props)

const fileInput = ref() // file input template ref

const {
  errors: fileErrors,
} = useField(nameToUse('file'))

const videoMimeTypes = import.meta.env.VITE_VALID_VIDEO_MIMETYPES
const videoMaxSize = import.meta.env.VITE_VALID_MAX_FILE_SIZE_KB

const fileRules = [
  `mimes:${videoMimeTypes}`,
  `size.file:${videoMaxSize}`,
].join('|')

const currentFile = computed(() => (
  get(form.value?.values, nameToUse('file'))
))

const videoUrl = computed(() => {
  // no picture url if it is marked for destruction
  if (!markedForDestruction.value) {
    // uploaded picture
    if (currentFile.value) {
      return URL.createObjectURL(currentFile.value)
    }

    // resource current picture
    return props.resource?.attributes?.url
  }

  return null
})

function handleFileUpload(e) {
  // Check if a file is present,
  // useful when upload is cancel
  const targetFile = e.target.files?.[0]
  if (targetFile) {
    // assign new picture value
    form.value?.setFieldValue(nameToUse('file'), targetFile)
    form.value?.setFieldValue(nameToUse('_destroy'), false)
  }
}
</script>
