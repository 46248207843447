<template>
  <div>
    <draggable
      :list="fieldsValues"
      item-key="id"
      class="flex flex-wrap"
      animation="200"
      @change="handleSort"
    >
      <template
        #item="{element, index}"
      >
        <div
          v-show="!element._destroy"
          class="mr-3 mb-3 w-38 relative"
        >
          <!-- Remove button -->
          <app-button
            feature="close"
            :hide-icon="false"
            :hide-text="true"
            width="6"
            height="6"
            rounded="rounded-1/2"
            padding="p-0"
            :gradient="false"
            :icon-props="{ size: 'text-sm' }"
            class="absolute -top-2 -right-2 flex items-center justify-center z-10"
            @click="handleFormRemove(index)"
          />

          <!-- Nested form fields -->
          <videos-form-nested-fields
            :ref="(refItem) => setFormFieldsRef(index, refItem)"
            :resource="nestedResource(element)"
            :name-prefix="`${name}.${index}`"
          />
        </div>
      </template>

      <template #footer>
        <!-- Add button -->
        <app-button
          v-if="addButtonDisplayable"
          feature="add"
          :hide-icon="false"
          :hide-text="true"
          rounded="rounded-md"
          :icon-props="{ size: 'text-5xl' }"
          width="38"
          height="22"
          padding="p-0"
          font-color-classes="text-theme-500 hover:text-theme-600"
          background-classes="bg-white"
          class="mb-3"
          @click="handlePlusClick"
        />
      </template>
    </draggable>
  </div>
</template>

<script setup>
import { nextTick, ref, computed } from 'vue'
import draggable from 'vuedraggable'

import AppButton from '@shared/components/ui/AppButton.vue'
import VideosFormNestedFields from '@extranet/components/resources/point_of_interest/videos/VideosFormNestedFields.vue'
import useFormNested from '@shared/hooks/form/formNested'

const props = defineProps({
  // JSON API resource used
  // to populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Attributes prefix name in form
  name: {
    type: String,
    default: null,
  },
})

const {
  nestedResource,
  handleFormAdd,
  handleFormRemove,
  handleSort,
  markForDestruction,
  fieldsValues,
  nonDestroyedValues,
} = useFormNested(props)

// Maximum number videos that can be added
const maxVideos = 1

const nestedFormsFieldsEls = ref([]) // form fields template refs
function setFormFieldsRef(index, element) {
  nestedFormsFieldsEls.value[index] = element
}

function handlePlusClick() {
  handleFormAdd(async (newVideoIndex) => {
    // Mark to destroy by default, it has to be unmark at file upload in the FormFields component
    markForDestruction(newVideoIndex)
    // Make sure that the new item has a right position
    handleSort()

    await nextTick()

    // Trigger file input upload
    nestedFormsFieldsEls.value[newVideoIndex].$refs.fileInput.click()
  })
}

const addButtonDisplayable = computed(() => (
  nonDestroyedValues.value?.length < maxVideos
))
</script>
