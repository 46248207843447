import { computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import useFormWizard from '@extranet/hooks/formWizard'

export default function useClaimForm(props) {
  const { t } = useI18n()

  // ---------- FORM ----------

  const { form } = useFormWizard(props)

  const countryValue = computed(() => (
    form.value.values.country
  ))

  const companyTypeValue = computed(() => (
    form.value.values.company_type
  ))

  // ---------- COMPANY TYPE ----------

  const isCompany = computed(() => (
    companyTypeValue.value === 'company'
  ))

  const isIndividual = computed(() => (
    companyTypeValue.value === 'individual'
  ))

  const isAssociation = computed(() => (
    companyTypeValue.value === 'association'
  ))

  const isOther = computed(() => (
    companyTypeValue.value === 'other'
  ))

  // ---------- HINTS ----------

  /**
   * Get translation for a specific hint depending on country
   *
   * @returns {string}
   */
  function getDocumentHintTranslation() {
    return t(`claims.form_wizard.documents.${countryValue.value}.${companyTypeValue.value}`)
  }

  watch(countryValue, (newValue) => {
    if (!form.value.values.phone) {
      form.value.setFieldValue(
        'phone_country_code',
        newValue,
      )
    }
  })

  return {
    countryValue,
    companyTypeValue,
    isCompany,
    isIndividual,
    isAssociation,
    isOther,
    getDocumentHintTranslation,
  }
}
