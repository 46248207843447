export default function useDocuments() {
  const documentMimeTypes = import.meta.env.VITE_VALID_DOCUMENT_MIMETYPES
  const documentMaxSize = import.meta.env.VITE_VALID_MAX_DOCUMENT_SIZE_KB

  const documentRules = [
    `mimes:${documentMimeTypes}`,
    `size.file:${documentMaxSize}`,
  ].join('|')

  return {
    documentMimeTypes,
    documentMaxSize,
    documentRules,
  }
}
