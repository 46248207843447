<template>
  <template v-if="isCurrentStep">
    <form-group
      rules="required"
      name="country"
      type="select"
      :label="t('validation.attributes.country')"
      :form-control-props="{
        options: claimCountriesOptions,
      }"
    />

    <form-group
      rules="required"
      name="company_type"
      type="select"
      :label="t('validation.attributes.company_type')"
      :form-control-props="{
        options: claimCompanyTypesOptions,
      }"
    />

    <form-group
      rules="required"
      name="claim_confirmation"
      type="checkbox"
      flex-direction="row"
      margins="my-4"
      :label="t('points_of_interest.claim.confirm_checkbox')"
      :form-label-props="{
        required: false,
      }"
    />
  </template>

  <template v-else>
    <form-group
      type="hidden"
      name="claim_confirmation"
    />

    <form-group
      type="hidden"
      name="country"
    />

    <form-group
      type="hidden"
      name="company_type"
    />
  </template>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

import useSelectOptions from '@shared/hooks/form/selectOptions'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  step: {
    type: Object,
    default: () => ({}),
  },
  // Current step number
  // in form wizard
  currentStep: {
    type: Number,
    default: 0,
  },
})

const { t } = useI18n()

const {
  claimCountriesOptions,
  claimCompanyTypesOptions,
} = useSelectOptions()

// ========== CURRENT STEP ==========

const isCurrentStep = computed(() => (
  props.step.step === props.currentStep
))
</script>
