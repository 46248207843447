<template>
  <!-- Breadcrumb -->
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <!-- Main title -->
  <teleport-wrapper to="#mainTitle">
    {{ t('breadcrumbs.points_of_interest.points_of_interest') }}
  </teleport-wrapper>

  <!-- Help -->
  <teleport-wrapper to="#help">
    <tutorial-point-of-interest-index />
  </teleport-wrapper>

  <div class="mb-5 flex justify-between items-start md:items-center flex-col md:flex-row max-w-sm md:max-w-full">
    <!-- Search -->
    <form-group
      name="points_of_interest_search"
      :label="false"
      :form-control-props="{
        placeholder: t('points_of_interest.index.search'),
        iconProps: { library: 'coolicon', name: 'search', classes: ['form-icon-theme'], size: 'text-2xl' },
      }"
      class="max-w-sm w-full flex-1 mb-3 md:mb-0"
      @changed="handleSearch"
    />

    <!-- Create button -->
    <app-button
      v-if="store.state.auth.user.authorizations.points_of_interest.create"
      feature="create"
      :label="t('extranet.points_of_interest.actions.new')"
      class="w-full md:w-fit"
      @click="router.push({name: 'create-points-of-interest'})"
    />
  </div>

  <!-- Filters -->
  <app-filters
    class="my-4"
    :display-filters="hasActiveFilters"
  >
    <point-of-interest-filters
      @filters-applied="handleFiltersApplied"
    />
  </app-filters>

  <!-- Results table -->
  <div ref="appTableContainerRef">
    <app-table
      :headers="headers"
      :data="tableData"
      :loading="loading"
    >
      <template #colgroups>
        <colgroup>
          <col class="w-1/3">
          <col>
          <col>
          <col>
          <col class="w-28">
          <col>
        </colgroup>
      </template>
    </app-table>
  </div>

  <!-- Navigation buttons -->
  <div
    v-if="!loading"
    class="flex items-center justify-center mt-8"
  >
    <app-button
      v-if="previousPageAvailable"
      :label="t('pagination.previous_alt')"
      emphasis="low"
      @click="handlePreviousPage"
    />

    <span
      v-if="previousPageAvailable && nextPageAvailable"
      class="mx-4"
    >
      &bull;
    </span>

    <app-button
      v-if="nextPageAvailable"
      :label="t('pagination.next_alt')"
      emphasis="low"
      @click="handleNextPage"
    />
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  onUnmounted,
  ref,
  watch,
  inject,
} from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { isNil } from 'lodash'

import {
  fetchPointsOfInterest,
  destroyPointOfInterest,
  disableSynchronizationPointOfInterest,
} from '@shared/http/api'
import breadcrumbPointOfInterest from '@extranet/breadcrumbs/pointOfInterest'
import useForm from '@shared/hooks/form/form'
import useFilters from '@shared/hooks/filters'
import useLocale from '@shared/hooks/locale'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import AppTable from '@extranet/components/ui/table/AppTable.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppFilters from '@extranet/components/ui/AppFilters.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import PointOfInterestWithMainPicture from '@extranet/components/resources/point_of_interest/PointOfInterestWithMainPicture.vue'
import PointOfInterestTableActions from '@extranet/components/resources/point_of_interest/PointOfInterestTableActions.vue'
import PointOfInterestFilters from '@extranet/components/resources/point_of_interest/PointOfInterestFilters.vue'
import TutorialPointOfInterestIndex from '@extranet/components/tutorials/point_of_interest/TutorialPointOfInterestIndex.vue'
import { distanceToDisplay } from '@shared/helpers/geoloc'

const props = defineProps({})
defineEmits([
  'destroyed',
])

const router = useRouter()
const store = useStore()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ---------- POINTS OF INTEREST ----------

const nextPageAvailable = ref(false)
const previousPageAvailable = ref(false)
const pointsOfInterest = ref([])
const loading = ref(true)

function fetchPois() {
  loading.value = true

  // Build params
  const params = {
    'page': page.value,
    'search': searchKeywords.value,
    'mode': 'index',
    'source': 'extranet',
    'filters': allFieldsToSubmit.value,
    'order': allFieldsToSubmit.value.order,
  }

  // Refresh measurement system
  measurementSystemApplied.value = allFieldsToSubmit.value.measurement_system

  fetchPointsOfInterest(params)
    .then((response) => {
      // Replace the old results with the new ones
      pointsOfInterest.value = response.data.data?.map((pointOfInterest) => ({
        resource: pointOfInterest,
        destroyCallback: () => handleDestroy(pointOfInterest.id),
        importSyncDisabled: () => handleImportSyncDisable(pointOfInterest.id),
      })) ?? []

      // Check if navigation available
      previousPageAvailable.value = !isNil(response.data.links?.prev)
      nextPageAvailable.value = !isNil(response.data.links?.next)

      // Scroll to results
      appTableContainerRef.value?.scrollIntoView({ behavior: 'smooth' })
    })
    .finally(() => {
      loading.value = false
    })
}

function handleDestroy(id) {
  destroyPointOfInterest(id).then(() => {
    resetPage()
    fetchPois()
    store.commit(
      'flashes/ADD_FLASH',
      { message: t('points_of_interest.destroy.success'), type: 'success' },
    )
  })
}

// ---------- SEARCH ----------

const typingTimer = ref(0)
const searchKeywords = ref(null)

function handleSearch(value) {
  // Wait a little before emit the 'searched event'
  // it allows user to finish typing, and avoid to trigger an api call at each keystroke
  if (typingTimer.value) {
    clearTimeout(typingTimer.value)
  }
  typingTimer.value = setTimeout(() => {
    resetPage()
    searchKeywords.value = value
    fetchPois()
  }, 400)
}

// ---------- DATA TABLE ----------

const headers = [
  { label: t('validation.attributes.title') },
  { label: t('validation.attributes.category') },
  { label: t('validation.attributes.tags') },
  { label: t('validation.attributes.city') },
  { label: t('validation.attributes.distance') },
  { label: t('validation.attributes.actions') },
]

const tableData = computed(() => (
  pointsOfInterest?.value?.map((poi) => ({
    title: {
      component: PointOfInterestWithMainPicture,
      props: {
        resource: poi.resource,
      },
    },
    category: getAttributeTranslation(poi.resource.relationships.category?.attributes?.label),
    tags: displayTags(poi.resource.relationships.tags),
    city: getAttributeTranslation(poi.resource.relationships.address?.attributes?.city),
    distance: distanceToDisplay(
      measurementSystemApplied.value,
      poi.resource.attributes.distance,
    ),
    actions: {
      component: PointOfInterestTableActions,
      props: {
        resource: poi.resource,
        destroyCallback: () => handleDestroy(poi.resource.id),
        importSyncDisabled: () => handleImportSyncDisable(poi.resource.id),
      },
    },
  }))
))

// ---------- PAGINATION ----------

const appTableContainerRef = ref(null)
const page = ref(1)

function resetPage() {
  page.value = 1
}

function handlePreviousPage() {
  page.value -= 1
  fetchPois()
}

function handleNextPage() {
  page.value += 1
  fetchPois()
}

// ---------- BREADCRUMBS ----------

const { index } = breadcrumbPointOfInterest()

const breadcrumbs = computed(() => (
  index()
))

// ---------- FILTERS ----------

const {
  allFieldsToSubmit,
} = useForm(props, {
  initialValues: store.state.filters.pointsOfInterest,
  formName: 'pointsOfInterestIndexFiltersForm',
})

// Measurement system's filter applied during last search
const measurementSystemApplied = ref()

const {
  hasActiveFilters,
} = useFilters('pointsOfInterest')

function handleFiltersApplied() {
  resetPage()
  fetchPois()
}

// ---------- TAGS ----------

function displayTags(tags) {
  const tagList = tags?.map((tag) => (
    getAttributeTranslation(tag.attributes.label)
  )) ?? []

  if (tagList.length > 0) {
    return tagList.join(', ')
  }

  return t('points_of_interest.index.no_tags')
}

// ---------- UPDATE ----------

function handleImportSyncDisable(poi) {
  store.commit('spinner/DISABLE')
  disableSynchronizationPointOfInterest(poi)
    .then(() => {
      router.push({ name: 'edit-points-of-interest', params: { id: poi } })
    })
}

// ---------- REFRESH INDEX ----------

const pointsOfInterestIndexRefresh = inject('points_of_interest_index_refresh')
watch(pointsOfInterestIndexRefresh, () => {
  resetPage()
  fetchPois()
})

// ---------- LIFECYCLES ----------

onMounted(() => {
  // Filters' form-groups are rendered in child component
  // So must wait onMounted lifecycle hook to fetch resources with filters
  fetchPois()
})

onUnmounted(() => {
  store.commit('filters/RESET_FILTERS', 'pointsOfInterest')
})
</script>
