<template>
  <app-button
    v-if="resource.authorizations.delete"
    font-size="sm"
    feature="delete"
    @click="handleDestroy"
  />

  <!-- Modal -->
  <app-overlay
    :show="deleteConfirmModalDisplayed"
    @clicked="handleDestroyCancel"
  >
    <app-modal @closed="handleDestroyCancel">
      <app-confirm
        @cancelled="handleDestroyCancel"
        @confirmed="handleDestroyConfirm"
      >
        <p>
          {{
            t('points_of_interest.destroy.confirm', {
              point_of_interest_name: getAttributeTranslation(resource.attributes.title)
            })
          }}
        </p>
      </app-confirm>
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import useLocale from '@shared/hooks/locale'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'destroyed',
])

const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// ---------- DELETE ----------

const deleteConfirmModalDisplayed = ref(false)

function handleDestroy() {
  deleteConfirmModalDisplayed.value = true
}

function handleDestroyConfirm() {
  deleteConfirmModalDisplayed.value = false
  emits('destroyed')
  props.destroyCallback()
}

function handleDestroyCancel() {
  deleteConfirmModalDisplayed.value = false
}
</script>
