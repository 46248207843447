<template>
  <form-group
    name="address.id"
    type="hidden"
  />

  <form-translated
    :form-group-props="{
      name: 'address.autocomplete',
      submittable: false,
      type: 'select',
      errorLabelI18nKey: 'line1',
      label: t('validation.attributes.line1'),
      initialValue: getAutocompleteInitialValueByLocale,
      formControlProps: {
        filterable: false,
        options: handleAddressSearch,
        minChars: 3,
      },
      formLabelProps: {
        required: true,
      },
    }"
    :form-group-events="{
      changed: handleAddressChange,
      input: handleAutocompleteInput,
    }"
  >
    <template #control-vue-multiselect-option="option">
      <div>
        <template v-if="option.icon">
          <!-- Set a key to force icon to re-render when options change -->
          <font-awesome-icon
            :key="option.icon"
            :icon="option.icon"
          />
          &nbsp;
        </template>
        <strong>
          {{ option.label }}
        </strong>

        <template v-if="option.subLabel">
          <br>
          <em class="text-sm">
            {{ option.subLabel }}
          </em>
        </template>
      </div>
    </template>
  </form-translated>

  <form-translated
    :form-group-props="{
      name: 'address.line1',
      type: 'hidden',
    }"
  />

  <form-translated
    :form-group-props="{
      name: 'address.line2',
      type: 'text',
    }"
    :form-group-events="{
      changed: resetCoords,
    }"
  />

  <form-group
    name="address.zip"
    type="text"
    class="lg:mr-2"
    @changed="resetCoords"
  />

  <form-translated
    :form-group-props="{
      name: 'address.city',
      type: 'text',
      formLabelProps: {
        required: true,
      },
    }"
    :form-group-events="{
      changed: resetCoords,
    }"
  />

  <form-translated
    :form-group-props="{
      name: 'address.dependent_locality',
      type: 'text',
    }"
    :form-group-events="{
      changed: resetCoords,
    }"
  />

  <form-translated
    :form-group-props="{
      name: 'address.administrative_area',
      type: 'text',
    }"
    :form-group-events="{
      changed: resetCoords,
    }"
  />

  <form-group
    rules="required"
    name="address.country"
    type="select"
    :form-control-props="{
      options: countries,
    }"
    @changed="handleCountryChange"
  >
    <template #control-vue-multiselect-option="option">
      <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
    </template>
    <template #control-vue-multiselect-singlelabel="option">
      <span :class="`flag:${option.value}`" />&nbsp;{{ option.label }}
    </template>
  </form-group>

  <form-group
    name="address.latitude"
  />

  <form-group
    name="address.longitude"
  />

  <form-group
    type="switch"
    name="address.is_geocoding"
    :label="t('validation.attributes.is_geocoding')"
    :hint="t('form.hints.point_of_interest.is_geocoding')"
  />
</template>

<script setup>
import { computed, nextTick, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { countries as countriesList } from 'countries-list'
import {
  uniqBy,
  uniq,
} from 'lodash'

import FormGroup from '@shared/components/form/FormGroup.vue'
import FormTranslated from '@shared/components/form/FormTranslated.vue'
import useGeoloc from '@shared/hooks/geoloc'
import useFormFields from '@shared/hooks/form/formFields'
import * as mapbox from '@shared/http/mapbox'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()
const { countries } = useGeoloc()
const { form } = useFormFields(props)

const formLocale = computed(() => form.value.values.locale_selector)

// ---------- FILTER OPTIONS ----------

const addressOptionsFromApi = ref([])
const initialAddressSearch = ref({}) // initial address search per locale

// Search for an address, in a specific locale
function handleAddressSearch(searchKeywords, autocompleteLocale) {
  return new Promise((resolve) => {
    let options = []

    if (initialAddressSearch.value) {
      initialAddressSearch.value[autocompleteLocale] = false

      if (form.value?.values?.address?.line1?.[autocompleteLocale]) {
        // Provide an option with the resource's attribute value
        options.push({
          label: form.value.values.address.line1[autocompleteLocale],
          value: form.value.values.address.line1[autocompleteLocale],
        })
      }
    }

    // Add search keyword to available options
    if (searchKeywords?.length > 0) {
      options.push({
        value: searchKeywords,
        label: searchKeywords,
        icon: 'pen',
      })

      mapbox.geocoding(searchKeywords, {
        types: [
          'address',
          'poi',
        ].join(','),
        language: autocompleteLocale,
      })
        .then((r) => {
          addressOptionsFromApi.value = r.data.features
            ?.map((place) => {
              // Build option's label
              let label = ''
              // Specify address in the label when available
              if (place.place_type.includes('address') && place.address) {
                label = `${place.address} ${place.text}`
              } else if (place.place_type.includes('poi') && place.properties?.address) {
                label = `${place.text}, ${place.properties.address}`
              } else {
                label = place.text
              }

              // Adapt icon to place's type
              let icon
              if (place.place_type.includes('poi')) {
                icon = 'map-marker-alt'
              } else if (place.place_type.includes('address')) {
                icon = 'map-signs'
              }

              // Build sublabel from place's context
              const subLabel = uniq(place.context?.map((c) => c.text))
                .join(', ')

              return {
                value: place.id,
                label,
                subLabel,
                icon,
                context: place.context,
                coords: {
                  latitude: place.center[1],
                  longitude: place.center[0],
                },
              }
            })
        })
        .finally(() => {
          options = options.concat(addressOptionsFromApi.value)
          resolve(uniqBy(options, 'value'))
        })
    } else {
      resolve(options)
    }
  })
}

// Sync line 1 hidden field's value with autocomplete input
function handleAutocompleteInput(event) {
  form.value.setFieldValue(
    `address.line1.${formLocale.value}`,
    event.target.value,
  )
}

async function handleAddressChange(value) {
  // Retrieve selected option in api options list
  const option = addressOptionsFromApi.value.find((filter) => (filter.value === value))

  // If option retrieved
  // use option's label as line 1 hidden field's value
  // else, keep line 1 value
  if (option) {
    form.value.setFieldValue(
      `address.line1.${formLocale.value}`,
      option.label ?? null, // Force to null to avoid undefined value
    )
  }

  // Fill other form fields from mapbox context data
  // if context data is undefined, force field to null
  if (option?.context) {
    let context_dependent_locality = option.context.find((context) => context.id.startsWith('locality'))
    let context_administrative_area = option.context.find((context) => context.id.startsWith('region'))
    const context_city = option.context.find((context) => context.id.startsWith('place'))
    const context_zip = option.context.find((context) => context.id.startsWith('postcode'))
    const context_country = option.context.find((context) => context.id.startsWith('country'))

    if (!context_dependent_locality) {
      context_dependent_locality = option.context.find((context) => context.id.startsWith('neighborhood'))
    }

    if (!context_administrative_area) {
      context_administrative_area = option.context.find((context) => context.id.startsWith('district'))
    }

    form.value.setFieldValue(`address.dependent_locality.${formLocale.value}`, context_dependent_locality?.text || null)
    form.value.setFieldValue(`address.city.${formLocale.value}`, context_city?.text || null)
    form.value.setFieldValue('address.zip', context_zip?.text || null)
    form.value.setFieldValue(`address.administrative_area.${formLocale.value}`, context_administrative_area?.text || null)
    form.value.setFieldValue('address.country', context_country?.short_code?.toUpperCase() || null)
    form.value.setFieldValue(`address.line2.${formLocale.value}`, null)
  }

  // Reset latitude and longitude anyway
  resetCoords()

  // Wait for each address field to change, which reset coords, and at last set the new coords
  await nextTick()

  if (option?.coords) {
    form.value.setFieldValue('address.latitude', option.coords.latitude)
    form.value.setFieldValue('address.longitude', option.coords.longitude)
  }
}

function handleCountryChange(country) {
  resetCoords()

  // Adapt phone country code if field is empty
  if (!form.value.values.contact.phone) {
    form.value.setFieldValue(
      'contact.phone_country_code',
      country,
    )
  }

  // Adapt language field
  form.value.setFieldValue(
    'locale',
    countriesList[country]?.languages?.[0],
  )
}

function resetCoords() {
  form.value.setFieldValue('address.latitude', null)
  form.value.setFieldValue('address.longitude', null)
}

function getAutocompleteInitialValueByLocale(autocompleteLocale) {
  // Get autocomplete initial value in a specific locale
  return form.value?.values?.address?.line1?.[autocompleteLocale]
}
</script>
