<template>
  <app-button
    font-size="sm"
    :label="adviceExist ? t('advice.ui.edit_advice') : t('advice.ui.create_advice')"
    feature="add"
    @click="handleModalAction"
  />

  <app-overlay
    :show="confirmModalDisplayed"
  >
    <app-modal
      @closed="handleCancel"
    >
      <template #title>
        <h4 class="text-2xl font-semibold">
          {{ adviceModalTitle }}
        </h4>
      </template>

      <manage-advice-form
        :resource="resource?.relationships?.advice"
        :advice-exist="adviceExist"
        :additional-errors="formErrors"
        @submitted="handleSubmit"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import useAuthUser from '@shared/hooks/auth/authUser'
import {
  storeAdvice,
  updateAdvice,
  destroyAdvice,
} from '@shared/http/api'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import ManageAdviceForm from '@extranet/components/resources/point_of_interest/advices/ManageAdviceForm.vue'

const { t } = useI18n()

const confirmModalDisplayed = ref(false)
const formErrors = ref({})

const { authUser } = useAuthUser()
const route = useRoute()

const props = defineProps({
  // Point of interest
  resource: {
    type: Object,
    default: null,
  },
})

const emits = defineEmits([
  'adviceUpdated',
])

// ---------- MODAL ----------

function handleModalAction() {
  confirmModalDisplayed.value = true
}

function handleCancel() {
  confirmModalDisplayed.value = false
}

// ---------- COMPUTED ------------

const authPOIOwnerId = computed(() => {
  if (authUser.value?.attributes?.point_of_interest_id !== null) {
    return authUser.value?.relationships?.point_of_interest?.attributes?.owner_id
  }

  return authUser.value.id
})

const adviceExist = computed(() => (
  props.resource?.relationships?.advice
))

// Check if advice exist for user and poi
const adviceModalTitle = computed(() => (adviceExist.value ? t('advice.ui.edit_advice') : t('advice.ui.create_advice')))

// ----------- STORE ----------

/**
 * If the data is validated, we send the payload to the API.
 *
 * @param { attributes } attributes
 */
async function handleSubmit(attributes) {
  // If no currently advice

  if (!adviceExist.value) {
    // Create Advice
    const params = {
      data: {
        type: 'advices',
        attributes: {
          ...attributes,
          ...{
            manager_id: authPOIOwnerId.value,
            point_of_interest_id: route.params.id,
          },
        },
      },
    }

    store(params)
  } else {
    // If Advice exist, check if update or delete.
    const adviceId = props.resource?.relationships?.advice?.id

    if (attributes?.delete) {
      destroy(adviceId)
    } else {
      const params = {
        data: {
          id: adviceId,
          type: 'advices',
          attributes: {
            ...attributes,
            manager_id: authPOIOwnerId.value,
            point_of_interest_id: route.params.id,
          },
        },
      }

      update(adviceId, params)
    }
  }
}

/**
 * Send to advice's creation endpoint
 *
 * @param { Object } params
 */
const store = (params) => storeAdvice(params)
  .then(() => {
    emits('adviceUpdated', 'created')
  })
  .catch((e) => {
    if (e.response?.data?.errors) {
      formErrors.value = e.response.data.errors
    }
  })

/**
 * Send to advice's update endpoint
 *
 * @param { number } id
 * @param { Object } params
 */
const update = (id, params) => updateAdvice(id, params)
  .then(() => {
    emits('adviceUpdated', 'updated')
  })
  .catch((e) => {
    if (e.response?.data?.errors) {
      formErrors.value = e.response.data.errors
    }
  })

/**
 * Remove an advice
 *
 * @param { number } id
 */
const destroy = (id) => destroyAdvice(id)
  .then(() => {
    emits('adviceUpdated', 'deleted')
  })
  .catch((e) => {
    if (e.response?.data?.errors) {
      formErrors.value = e.response.data.errors
    }
  })
</script>
