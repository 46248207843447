import useForm from '@shared/hooks/form/form'

export default function useAdviceForm(props, options = {}) {
  const {
    submitting,
    handleSubmit,
    errors,
  } = useForm(props, options)

  return {
    errors,
    submitting,
    handleSubmit,
    props,
  }
}
