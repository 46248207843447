import useLocale from '@shared/hooks/locale'

export default function useTag(props) {
  const { getAttributeTranslation } = useLocale()

  function displayTags(separator = ' • ') {
    if (props.resource.relationships?.tags) {
      const tags = []

      props.resource.relationships.tags.map((tag) => (
        tags.push(getAttributeTranslation(tag.attributes.label))
      ))

      return tags.join(separator)
    }

    return null
  }

  return {
    displayTags,
  }
}
